import { BrowserModule,Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { EditorModule } from '@tinymce/tinymce-angular';

import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule }  from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { McComponent } from './mc/mc.component';
import { MenuComponent } from './menu/menu.component';
// import { HomeComponent } from './home/home.component';
import { AuthComponent } from './auth/auth.component';
import { LoginComponent } from './auth/login/login.component';
import { ForgotPwdComponent } from './auth/forgot-pwd/forgot-pwd.component';
import { LogoutComponent } from './auth/logout.component';
import { ProfileComponent } from './profile/profile.component';

import { ChatComponent } from './mc/chat/chat.component';
// import { EmailComponent } from './mc/email/email.component';
import { ServerService } from './services/server.service';

import { AdminSettingsComponent } from './admin-settings/admin-settings.component';
// import { SmsComponent } from './mc/sms/sms.component';
import { ContactsComponent } from './contacts/contacts.component';

import { EditContactsComponent } from './edit-contacts/edit-contacts.component';

// import { ContactReportComponent } from './contact-report/contact-report.component';

import { AppSettingsComponent } from './app-settings/app-settings.component';

// import { WallboardComponent } from './wallboard/wallboard.component';
import { GlobalSettingsComponent } from './global-settings/global-settings.component';

// import { DemoPageComponent } from './demo-page/demo-page.component';

import { SafePipe } from './safe.pipe';

import { GaugeChartModule } from 'angular-gauge-chart'
import { GoogleChartsModule } from 'angular-google-charts';
import { UserIdleModule } from 'angular-user-idle';
// import { TestComponent } from './test/test.component';
import { ReportComponent } from './report/report.component';
// import { SocialLoginModule, AuthServiceConfig, FacebookLoginProvider } from 'angularx-social-login';
// import { InternalChatComponent } from './mc/internal-chat/internal-chat.component';

import { FooterComponent } from './footer/footer.component';

import { AngularFireModule } from '@angular/fire';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { environment } from '../environments/environment';

import {DatePipe} from '@angular/common';


// import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
// import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { RichTextEditorAllModule } from '@syncfusion/ej2-angular-richtexteditor';
// import { VideosBlogComponent } from './videos-blog/videos-blog.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { CdTimerComponent } from 'angular-cd-timer';

// const config = new AuthServiceConfig([
//     {
//       id: FacebookLoginProvider.PROVIDER_ID,
//       provider: new FacebookLoginProvider('228820218341303')
//     }
//   ]);
  
//   export function provideConfig() {
//     return config;
//   }
const appRoutes: Routes = [
    {
        path :'', component:ContactsComponent
    },
    // {
    //     path :'test', component:TestComponent
    // },

    {
        path :'login', component:LoginComponent
    },{
        path :'forgot-pwd', component:ForgotPwdComponent
    },{
        path :'profile', component:ProfileComponent
    },{
    //     path :'agents', component:AgentsComponent
    // },{
    //     path :'call', component:CallComponent
    // },{
    //     path :'call-history', component:CallHistoryComponent
    // },{
        path :'mc', component:McComponent
    },

    {
        path :'admin-settings', component:AdminSettingsComponent
    },
    {
        path :'logout', component:LogoutComponent
    },
    // {
    //     path :'sms', component:SmsComponent
    // },
   
    {
        path :'contacts', component:ContactsComponent
    },
    {
        path :'get_employee', component:EditContactsComponent
    },

    // {
    //     path :'contact-report', component:ContactReportComponent
    // },
    
    {
        path :'chat', component:ChatComponent
    },
   
    {
        path :'app-settings', component:AppSettingsComponent
    },

    // {
    //     path :'wallboard', component:WallboardComponent
    // },
    {
        path :'global-settings', component:GlobalSettingsComponent
    },
    // {
    //     path :'demo-page', component:DemoPageComponent
    // },
    // {
    //     path :'internal-chat', component:InternalChatComponent
    // },
    {
        path :'report-admin', component:ReportComponent
    },
    // {
    //     path :'vid-blog', component:VideosBlogComponent
    // }
];

    
@NgModule({
  declarations: [
    AppComponent,
    McComponent,
    MenuComponent,
    AuthComponent,
    LoginComponent,
    ForgotPwdComponent,
    ProfileComponent,
   
    ChatComponent,
    // EmailComponent,
  
    LogoutComponent,
  
    AdminSettingsComponent,
    // SmsComponent,
    ContactsComponent,
    EditContactsComponent,
    // ContactReportComponent,
    AppSettingsComponent,
    // WallboardComponent,
    GlobalSettingsComponent,

    // DemoPageComponent,
   
    SafePipe,
   
    // TestComponent,
    ReportComponent,
    // InternalChatComponent,
   
    FooterComponent,
    
    // VideosBlogComponent,
  
    
  
  ],
  imports: [
    BrowserModule,
    ReactiveFormsModule,GoogleChartsModule,FormsModule,
    HttpClientModule,EditorModule,  GaugeChartModule,
    // SocialLoginModule,
    UserIdleModule.forRoot({idle: 10, timeout: 10, ping: 10}),
    RouterModule.forRoot(appRoutes,{ useHash: true }),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireMessagingModule,
    //RichTextEditorAllModule,
    InfiniteScrollModule
  ],
  providers: [DatePipe,
//     {
//     provide: AuthServiceConfig,
//     useFactory: provideConfig
//   },
  Title],
  bootstrap: [AppComponent]
})
export class AppModule { }
