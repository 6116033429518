import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ServerService } from '../services/server.service';
declare var $:any;
declare var iziToast:any;
import Swal from 'sweetalert2'
@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.css']
})
export class ContactsComponent implements OnInit {
  contacts_list;
  recordNotFound = false;
  pageLimit = 10;
  paginationData:any ={"info":"hide"};
  offset_count = 0;
  agent_count;
  user_type;
  user_id;
  admin_id;
  editEmp:FormGroup;
  
 
emp_id;

  constructor(private serverService: ServerService, private router:Router) { }

  ngOnInit() {
    this.user_type = localStorage.getItem('user_type');
    this.user_id = localStorage.getItem('userId');
    this.admin_id = localStorage.getItem('admin_id');
    this.editEmp=new FormGroup({
      'emp_id':new FormControl(null),
      'emp_name':new FormControl(null),
      'emp_phone':new FormControl(null),
      'emp_email':new FormControl(null),
      'create_date':new FormControl(null),
      'inact_data':new FormControl(null)

    })
    this.contactsList({});
  }


  contactsList(data){
    var list_data= this.listDataInfo(data);
    let api_req:any = new Object();
    let agents_req:any = new Object();
 
      
    agents_req.action="list_emp";
    agents_req.admin_id=localStorage.getItem('admin_id');
    agents_req.search_text=list_data.search_text;
    agents_req.order_by_name=list_data.order_by_name;
    agents_req.order_by_type=list_data.order_by_type;
    agents_req.limit=list_data.limit;
    agents_req.offset=list_data.offset;
    api_req.operation="emp";
    api_req.moduleType="emp";
    api_req.api_type="web";
    api_req.access_token=localStorage.getItem('access_token');
    api_req.element_data = agents_req;
    this.serverService.sendServer(api_req).subscribe((response:any) => {
    
        if(response.result.status==1){
    console.log(response)
          this.contacts_list=response.result.data.list_data;
            this.offset_count = list_data.offset;
            this.paginationData = this.serverService.pagination({'offset':response.result.data.list_info.offset, 'total':response.result.data.list_info.total, 'page_limit' :this.pageLimit });
            this.recordNotFound = this.contacts_list.length == 0 ? true : false;
        }
        

    }, 
    (error)=>{
        console.log(error);
    });

}

listDataInfo(list_data){

  list_data.search_text = list_data.search_text == undefined ? "" : list_data.search_text;
  list_data.order_by_name = list_data.order_by_name == undefined ? "contact.contact_id" : list_data.order_by_name;
  list_data.order_by_type = list_data.order_by_type == undefined ? "desc" : list_data.order_by_type;
  list_data.limit = list_data.limit == undefined ? this.pageLimit : list_data.limit;
  list_data.offset = list_data.offset == undefined ? 0 : list_data.offset;
  return list_data;
}

editEmpdata(id){
  let access_token=localStorage.getItem('access_token');
  let api_req:any = '{"operation":"emp", "moduleType":"emp", "api_type": "web", "access_token":"'+access_token+'", "element_data":{"action":"edit_emp","admin_id":"'+this.user_id+'","id":"'+id+'"}}';
    
  this.serverService.sendServer(api_req).subscribe((response:any) => {
    if(response.result.status==true){
      console.log(response)
            let data=response.result.data[0];
            this.editEmp.setValue({
              'emp_id':data.emp_id,
              'emp_name':data.employee_name,
              'emp_phone':data.phone_number,
              'emp_email':data.email_address,
              'create_date':data.start_date,
              'inact_data':data.inactive_date
            });
$('#edit_Empform').modal('show');
             this.emp_id=data.emp_id;
          }
  },
  (error)=>{
console.log(error)
  });
}
updateEmployee(id){
  let access_token=localStorage.getItem('access_token');
  let data=this.editEmp.value;
  let api_req:any = new Object;
  api_req.operation="emp";
  api_req.moduleType="emp";
  api_req.api_type="web";
  api_req.access_token=localStorage.getItem('access_token');
  api_req.element_data =new Object;
  api_req.element_data.admin_id=this.admin_id;
  api_req.element_data.employee_id=id;
  api_req.element_data.id=id;
  api_req.element_data.employee_name=this.editEmp.value.emp_name;
  api_req.element_data.email_address=this.editEmp.value.emp_email;
  api_req.element_data.phone_number=this.editEmp.value.emp_phone;
  api_req.element_data.start_date=this.editEmp.value.create_date; 
  api_req.element_data.inactive_date=this.editEmp.value.inact_data;
  api_req.element_data.action="update_emp";
  // let api_req:any = '{ "operation":"emp","moduleType": "emp","api_type": "web","element_data":{ "action":"update_emp", "employee_name":"cal4care", "employee_id":"111", "email_address":"adadsda", "phone_number":"443565", "start_date":"31242356", "inactive_date":"42543687t9", "id":"4" } }';
    
  this.serverService.sendServer(api_req).subscribe((response:any) => {
    if(response.result.status==true){
     console.log(response)
      $('#edit_Empform').modal('hide');
             this.contactsList({});
          }
  },
  (error)=>{
console.log(error)
  });
}
deletedata(id){
    
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.value) {
      let access_token: any=localStorage.getItem('access_token');
    
      let api_req:any = '{"operation":"emp", "moduleType":"emp", "api_type": "web", "access_token":"'+access_token+'", "element_data":{"action":"del_emp","id":"'+id+'","admin_id":"'+this.admin_id+'"}}';
    
      this.serverService.sendServer(api_req).subscribe((response:any) => {

        console.log(response);
        if(response.result.data==true){
          iziToast.success({
            message: "Date deleted successfully",
            position: 'topRight'
        });
        this.contactsList({});
        } else {
          iziToast.warning({
            message: "Employee not deleted, Please try again!",
            position: 'topRight'
        });
        }
      }, 
      (error)=>{
          console.log(error);
      });
    
    }
  })
}





  

   


}
