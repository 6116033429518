import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { ServerService } from '../services/server.service';
import { DialpadComponent } from '../mc/dialpad/dialpad.component';
declare var $:any;
declare var iziToast:any;
declare var medi: any;
declare var dialPadOpen: any;
import Swal from 'sweetalert2'
@Component({
  selector: 'app-edit-contacts',
  templateUrl: './edit-contacts.component.html',
  styleUrls: ['./edit-contacts.component.css']
})
@Pipe({
  name: 'safe'
})
export class EditContactsComponent implements OnInit {




  param1: string;
  created_time;
  modified_time;
  contact_id;
  editContact: FormGroup;
  departments;
  uadmin_id;
  auxcodes;
  fromEdit;
  questions;
  to_num;
  call_record_id;
  admin_id;
  dailyfood = false;
  alladmin = true;
  dsk_access;
  hasdsk = false
  hasnodsk = true;
  external_contact_url;
  public allowMp = true;
  public startrec = false;
  dailyfoodurl;
  has_external_contact;
  IFlink;
  show_caller_id;
  hide_cal1er = '0';
  click_to_call_number;
  click_to_call_mobile_number;
  click_to_call_office_phone;
  click_to_call_home_phone;
  crm_type;
  hapikey;
  paramq;
  dailyfoodurlhub;
  z_authId;
  z_orgId;
  paramCall;
  popupnumber;

  constructor(private serverService: ServerService, private route: ActivatedRoute) { 
    
    this.param1 = this.route.snapshot.queryParamMap.get('phone');
    // var decodedString = atob(this.param1);
    // this.param1 = decodedString;
  }
  
  ngOnInit() {
    this.editContact = new FormGroup({
      'contact_owner' :new FormControl(null,Validators.required),
      'first_name' :new FormControl(null),
      'last_name' :new FormControl(null),
      'account_name' :new FormControl(null,Validators.required),
      'lead_source' :new FormControl(null,Validators.required),
      'title' :new FormControl(null),
      'email' :new FormControl(null,[
        Validators.required,
        Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]),
      'activity' :new FormControl(null),
      'phone' :new FormControl(null,Validators.required),
      'home_phone' :new FormControl(null),
      'office_phone' :new FormControl(null),
      'fax' :new FormControl(null),
      'mobile' :new FormControl(null),
      'dob' :new FormControl(null),
      'assistant' :new FormControl(null),
      'assitant_phone' :new FormControl(null),
      'reports_to' :new FormControl(null),
      'email_opt_out' :new FormControl(0),
      'skype' :new FormControl(null),
      'secondary_email' :new FormControl(null),
      'twitter' :new FormControl(null),
      'reporting_to' :new FormControl(null),
      'mailing_street' :new FormControl(null),
      'other_street' :new FormControl(null),
      'mailing_city' :new FormControl(null),
      'other_city' :new FormControl(null),
      'mailing_province' :new FormControl(null),
      'other_province' :new FormControl(null),
      'mailing_postal_code' :new FormControl(null),
      'other_postal_code' :new FormControl(null),
      'mailing_country' :new FormControl(null),
      'other_country' :new FormControl(null),
      'created_by' :new FormControl(null),
      'notes' :new FormControl(null),
      'modified_by' :new FormControl(null),
      'whatsapp_number' :new FormControl(null),
      'line' :new FormControl(null),
      'facebook_url' :new FormControl(null),
      'wechat' :new FormControl(null),
      'viber' :new FormControl(null),
      'telegram' :new FormControl(null),
      'instagram_url' :new FormControl(null),
      'linkedin' :new FormControl(null),
      'country_code' : new FormControl(null)
     });
     this.uadmin_id = localStorage.getItem('userId');
     this.show_caller_id = localStorage.getItem('show_caller_id');

     this.editConatcts();

    
    this.admin_id = localStorage.getItem('admin_id');
    this.dsk_access = localStorage.getItem('dsk_access');
    this.has_external_contact = localStorage.getItem('has_external_contact');
    this.external_contact_url = localStorage.getItem('external_contact_url');
    this.crm_type = localStorage.getItem('crm_type');
 

 
  }


editConatcts(){
  // alert(this.paramCall);
  // alert(this.param1);
  let conct_req:any = new Object();
  let api_req:any = new Object();
  conct_req.admin_id=localStorage.getItem('admin_id');
  conct_req.action="get_emp_ph";
  conct_req.phone_no = this.param1;
  api_req.operation="emp";
  api_req.moduleType="emp";
  api_req.api_type="web";
  api_req.access_token=localStorage.getItem('access_token');
  api_req.element_data = conct_req;

    this.serverService.sendServer(api_req).subscribe((response:any) => {    

        if(response.result.data!=''){
          var data=response.result.data[0];
            $('#emp_id').val(data.employee_id);
            $('#Emp_name').val(data.employee_name);
            $('#Emp_phone').val(data.phone_number);
            $('#emp_email').val(data.email_address);
            $('#emp_start_date').val(data.start_date);
            $('#emp_inact_date').val(data.inactive_date);
          
    }    
    else{
      iziToast.warning({
        message:"Employee Details not found",
        position:"topRight"
      })
    }

    
    (error)=>{
        console.log(error);
    }
  });
}

}
